import React from 'react';
import EvaluationAuth from '../screens/auth/EvaluationAuth';
import { Redirect, Router } from '@reach/router';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth } from '../services/firebase';
import MatchesAuth from '../screens/auth/MatchesAuth';

export interface MatchesPageProps {}

const MatchesPage: React.FunctionComponent<MatchesPageProps> = (): JSX.Element => {
  const [user, loading, error] = useAuthState(getFirebaseAuth());

  if (loading) {
    return <></>;
  }

  return user ? <MatchesAuth /> : <Redirect noThrow to="/" />;
};

export default MatchesPage;
